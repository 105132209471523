export const routePaths = {
    home: '/',
    login: '/login',
    education: '/education',
    job: '/job',
    projects: {
        root: 'projects',
        project: 'project/:id',
        add: 'project/add',
        edit: 'project/edit/:id'
    }
};
