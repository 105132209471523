import { AuthResponse } from '@api/models/view-models/response/auth-response';
import * as authActions from '@store/auth/auth.action';
import { authHelpers } from './auth.helpers';

export interface IAuthState {
    error?: string;
    loading: boolean;
    authData?: AuthResponse | undefined;
}

const initialState: IAuthState = {
    loading: false,
    error: undefined,
    authData: authHelpers.getAuthUser()
};

export const authReducer = (state = initialState, action: authActions.Actions): IAuthState => {
    switch (action.type) {
        case authActions.AUTH_LOGIN_START:
            return {
                ...state,
                loading: true
            };
        case authActions.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                authData: action.payload
            };
        case authActions.AUTH_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case authActions.AUTH_REFRESH_TOKEN:
            return {
                ...state,
                authData: action.payload
            };
        case authActions.AUTH_LOGOUT:
            return {
                ...state,
                authData: undefined
            };
        default:
            return state;
    }
};
