import { PageFilterViewModel } from '@api/models/view-models/base/page-filter-view-model';
import { PageResultViewModel } from '@api/models/view-models/base/page-result-view-model';
import React, { InputHTMLAttributes } from 'react';
import { ReactComponent as close } from '@assets/icons/close.svg';
import { ReactComponent as arrow_up } from '@assets/icons/arrow-up.svg';
import { ReactComponent as arrow_down } from '@assets/icons/arrow-down.svg';
import { ReactComponent as search } from '@assets/icons/search.svg';
import './select.style.scss';
import { Icon } from '@ui-kit/icon.ui';
import Input from '@components/input/input.component';
import useClickOutside from '@shared/hooks/use-click-outside';
import { classNames } from '@utils/style.utils';
import { FilterOperation } from '@api/models/enums/filter-operation.enum';
import { useDebounce } from '@shared/hooks/use-debounce';
import { FieldError } from 'react-hook-form';

interface Props<T, TID> extends InputHTMLAttributes<HTMLInputElement> {
    textField?: string;
    valueField?: string;
    selected?: TID;
    onLoadData?: () => Promise<PageResultViewModel<T>>;
    onSearchEvent?: (value: string | undefined) => void;
    request?: (request: PageFilterViewModel) => Promise<PageResultViewModel<T>>;
    filter?: PageFilterViewModel;
    data?: T[];
    onSelected?: (data: TID | undefined) => void;
    error?: FieldError | undefined;
}
const Select = <T, TID>({
    valueField = 'id',
    textField = 'name',
    selected,
    data,
    placeholder,
    onLoadData,
    onSearchEvent,
    request,
    filter,
    onSelected,
    name,
    error,
    ...props
}: Props<T, TID>) => {
    const { refClickOutside } = useClickOutside(() => setState({ ...state, expanded: false }));
    const [state, setState] = React.useState<{
        isLoading: boolean;
        isFocused: boolean;
        expanded: boolean;
        isEmpty: boolean;
        selected?: TID;
        data?: T[];
        filter?: PageFilterViewModel;
        search: string;
    }>({
        isLoading: true,
        isEmpty: true,
        isFocused: false,
        expanded: false,
        selected: undefined,
        data,
        filter,
        search: ''
    });
    const searchTerms = useDebounce(state.filter, 500);

    React.useEffect(() => {
        setState({ ...state, isLoading: true });
        if (data) {
            setState({ ...state, isLoading: false, data });
        }
    }, [state.filter]);

    React.useEffect(() => {
        if (selected && state.data && state.data.length) {
            setState({ ...state, selected: selected });
        }
    }, [state.data, selected]);

    React.useEffect(() => {
        loadData();
    }, [searchTerms]);

    const loadData = () => {
        if (request && state.filter) {
            request(state.filter)
                .then((res) => {
                    setState({ ...state, data: res.data, isLoading: false });
                })
                .catch(() => setState({ ...state, isLoading: false }));
        }
    };

    const removeAll = () => {
        setState({ ...state, selected: undefined, expanded: false });
        if (onSelected) {
            onSelected(undefined);
        }
    };

    const selectHandler = (id: TID, value: T) => {
        setState({ ...state, selected: id, expanded: false });
        if (onSelected) onSelected(id);
    };

    const css = classNames('', {
        expanded: state.expanded,
        selected: state.selected !== undefined,
        error: !state.expanded && error
    });

    const searhHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFilter = { ...state.filter } as PageFilterViewModel | undefined;
        if (event.target.value && event.target.value.length && newFilter) {
            const index = newFilter.query.findIndex((x) => x.field === textField);
            const value = {
                field: textField,
                operation: FilterOperation.ContainsAnd,
                values: [event.target.value]
            };
            if (index > -1) newFilter.query[index] = value;
            else newFilter.query.push(value);
            setState({ ...state, filter: newFilter, search: event.target.value });
        } else if (newFilter) {
            clearSearch(newFilter);
        }
    };

    const clearSearch = (newFilter: PageFilterViewModel | undefined) => {
        if (newFilter && newFilter.query) {
            const index = newFilter.query.findIndex((x) => x.field === textField);
            if (index > -1) newFilter.query.splice(index, 1);
            setState({ ...state, filter: newFilter, search: '' });
        }
    };

    const getSelectedTag = (id: any) => {
        const tag = state.data && state.data.find((x: any) => id === x[valueField]);
        if (tag) {
            return (tag as any)[textField];
        }
        return undefined;
    };

    return (
        <div ref={refClickOutside} className="custom-select">
            <Input
                touched={state.expanded || state.selected !== undefined}
                className={css}
                readOnly={true}
                placeholder={placeholder}
                onFocusCallback={() => setState({ ...state, expanded: true })}
                {...props}
            />
            <div className="tags">
                {state.selected && getSelectedTag(state.selected) && (
                    <span className="tag">{getSelectedTag(state.selected)}</span>
                )}
            </div>
            {state.selected !== undefined && <Icon className="clear" Svg={close} size={14} onClick={removeAll} />}
            {state.selected === undefined && (
                <Icon
                    className={css}
                    Svg={state.expanded ? arrow_up : arrow_down}
                    size={14}
                    onClick={() => {
                        const newFilter = { ...state.filter } as PageFilterViewModel | undefined;
                        clearSearch(newFilter);
                        setState({ ...state, expanded: !state.expanded });
                    }}
                />
            )}
            {!state.expanded && error && <span className="error-message">{error.message}</span>}
            {state.expanded && (
                <div className="select">
                    {!data && (
                        <div className="search">
                            <Input
                                Svg={state.search.length > 0 ? close : search}
                                SvgCallback={() => clearSearch(filter)}
                                value={state.search}
                                floatLable={false}
                                placeholder={'Search...'}
                                onChange={searhHandler}
                            />
                        </div>
                    )}
                    <div className="items">
                        {state.data && state.data.length ? (
                            state.data.map((v: any) => {
                                const selectedStyle = classNames('', {
                                    selected: state.selected !== undefined && state.selected === v[valueField]
                                });
                                return (
                                    <span
                                        key={v[valueField]}
                                        className={selectedStyle}
                                        onClick={() => {
                                            selectHandler(v[valueField], v);
                                        }}
                                    >
                                        {v[textField]}
                                    </span>
                                );
                            })
                        ) : (
                            <span className="no-data">No data</span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Select;
