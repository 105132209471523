import { Icon } from '@ui-kit/icon.ui';
import { classNames } from '@utils/style.utils';
import { Guid } from '@utils/uuid.utils';
import React, { useRef } from 'react';
import { ToastModelExtended } from './toast-model';
import { ReactComponent as close } from '@assets/icons/close.svg';
import { ReactComponent as warning } from '@assets/icons/warning.svg';
import './toast.style.scss';

interface Props {
    toast: ToastModelExtended;
    onClose: (id: Guid) => void;
}

export const Toast: React.FC<Props> = ({ toast, onClose }) => {
    const removeRef = useRef<any>();
    removeRef.current = onClose;

    React.useEffect(() => {
        if (toast.timeOut) {
            const id = setTimeout(() => {
                removeRef.current(toast.id);
            }, toast.timeOut);
            return () => clearTimeout(id);
        }
    }, []);

    const css = classNames('toast-notification', {
        success: toast.type === 'success',
        'warning-toast': toast.type === 'warning',
        info: toast.type === 'info'
    });

    const getColor = (): string => {
        switch (toast.type) {
            case 'success':
                return 'var(--color-g)';
            case 'warning':
                return 'var(--color-r)';
            case 'info':
                return 'var(--color-y)';
        }
    };

    return (
        <div className={css}>
            <Icon
                className="toast-close"
                Svg={close}
                size={24}
                fill={'var(--color-primary)'}
                onClick={() => onClose(toast.id)}
            />
            <div className="toast-icon">
                <Icon fill={getColor()} Svg={warning} size={24} />
            </div>
            <div className="toast-content">
                {toast.title && <p className="toast-title">{toast.title}</p>}
                <p className="toast-message">{toast.message}</p>
            </div>
        </div>
    );
};
