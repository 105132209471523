export const ApiPath = {
    projects: {
        list: 'project/list',
        get: 'project/',
        getEdit: 'project/edit/'
    },
    auth: {
        signIn: 'auth/signin',
        refresh: 'auth/refresh',
        signOut: 'auth/signout',
        getAuth: 'auth/user'
    },
    list: {
        list: 'listitem/list'
    }
};
