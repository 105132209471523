import { initialState, ISettingState } from './settings.reducer';

export class SettingsHelpers {
    storeSettings(settings: ISettingState) {
        localStorage.setItem('settings', JSON.stringify(settings));
    }

    loadSettings(): ISettingState {
        const settings = localStorage.getItem('settings');
        if (settings !== null) {
            const value = JSON.parse(localStorage.getItem('settings') || '');
            if (value) {
                return value as ISettingState;
            }
        }
        const initState = initialState;
        initState.currentLanguage = this.getDefaultLanguage();
        return initState;
    }

    getDefaultLanguage(): string {
        const v = window.navigator.language || 'en';
        const match = v.match(/\w\w/);
        if (match !== null) {
            return match[0];
        }
        return 'en';
    }
}

export const settingsHelpers = new SettingsHelpers();
