import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { CarouselDot } from './carousel-dot';
import { CarouselItem } from './carousel-item';
import './carousel.style.scss';

interface Props {
    children: React.ReactNode;
}

export const Carousel: React.FC<Props> = ({ children }) => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [lenght, setLenght] = React.useState<number>(0);
    const swipeHandler = useSwipeable({
        onSwipedLeft: () => setSelectedIndex(selectedIndex + 1),
        onSwipedRight: () => setSelectedIndex(selectedIndex - 1)
    });

    React.useEffect(() => {
        let i = 0;
        React.Children.map(children, (item) => (i = i + 1));
        setLenght(i);
    }, []);

    React.useEffect(() => {
        if (selectedIndex < 0) {
            setSelectedIndex(lenght - 1);
        } else if (lenght - 1 < selectedIndex) {
            setSelectedIndex(0);
        }
    }, [selectedIndex]);

    return (
        <div className="carousel">
            <div className="carousel-content" {...swipeHandler}>
                {React.Children.map(children, (item, index) => {
                    return (
                        <CarouselItem key={index} style={{ transform: `translateX(-${selectedIndex * 100}%)` }}>
                            {selectedIndex == index && item}
                        </CarouselItem>
                    );
                })}
            </div>
            <div className="carousel-dots">
                {React.Children.map(children, (item, index) => (
                    <CarouselDot
                        key={index}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        index={index}
                    />
                ))}
            </div>
        </div>
    );
};
