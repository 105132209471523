import { AuthProps, withAuth } from '@shared/hoc/withAuth';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routePaths } from './routePaths';

interface Props extends AuthProps {
    WComponent: React.FC;
    authRequire?: boolean;
}

const CheckAuthComponent: React.FC<Props> = ({ WComponent, authRequire, isAutorized }) => {
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (authRequire && !isAutorized) {
            navigate(routePaths.login);
        }
        if (location.pathname === routePaths.login && isAutorized) {
            navigate(routePaths.home);
        }
    });

    return <WComponent />;
};

export const CheckAuth = withAuth(CheckAuthComponent);
