import ToastContext from '@components/toast/toast-context';
import { useContext } from 'react';

const useToast = () => {
    const context = useContext(ToastContext);
    if (context !== null) {
        return { show: context.show };
    } else {
        throw new Error('Toast context cannot be null');
    }
};

export default useToast;