import React from 'react';
import api from './api';
import ApiContext from './api.context';

const ApiProvider: React.FC = ({ children }) => {
    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
};

export default ApiProvider;
