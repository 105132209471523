import { classNames } from '@utils/style.utils';
import React from 'react';
import './carousel.style.scss';
import { ReactComponent as dot } from '@assets/icons/dot.svg';
import { Icon } from '@ui-kit/icon.ui';

interface Props {
    selectedIndex: number;
    index: number;
    setSelectedIndex: (index: number) => void;
}

export const CarouselDot: React.FC<Props> = ({ selectedIndex, index, setSelectedIndex }) => {
    const onClick = React.useCallback(() => {
        setSelectedIndex(index);
    }, [setSelectedIndex, index]);

    const css = classNames('dot', {
        active: index === selectedIndex
    });

    return (
        <Icon
            className="css"
            Svg={dot}
            size={index === selectedIndex ? 18 : 12}
            fill={'var(--color-primary)'}
            onClick={onClick}
        />
    );
};
