import * as mainActions from './settings.action';
import { settingsHelpers } from './settings.helpers';

export interface ISettingState {
    currentLanguage: string;
}

export const initialState: ISettingState = {
    currentLanguage: 'en'
};

export const settingsReducer = (
    state: ISettingState = settingsHelpers.loadSettings(),
    action: mainActions.Actions
): ISettingState => {
    switch (action.type) {
        case mainActions.CHANGE_LANGUAGE:
            return { ...state, currentLanguage: action.payload };
        default:
            return state;
    }
};
