import './input.style.scss';

import React, { InputHTMLAttributes } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { classNames } from '@utils/style.utils';
import { useForwardRef } from '@shared/hooks/use-forward-ref';
import { Icon } from '@ui-kit/icon.ui';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
    floatLable?: boolean;
    touched?: boolean;
    onFocusCallback?: () => void;
    onChangeEvent?: (value: string) => void;
    Svg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    SvgCallback?: () => void;
}

const Input: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
    (
        {
            error,
            placeholder,
            onChange,
            onFocusCallback,
            onChangeEvent,
            Svg,
            SvgCallback,
            floatLable = true,
            touched = false,
            className,
            ...props
        },
        ref
    ) => {
        const inputRef = useForwardRef<HTMLInputElement>(ref);

        const [state, setState] = React.useState<{
            isFocused: boolean;
            isEmpty: boolean;
        }>({
            isEmpty: true,
            isFocused: false
        });

        React.useEffect(() => {
            if (inputRef && inputRef.current) {
                setState({ ...state, isEmpty: inputRef.current.value.length === 0 });
            }
        }, [inputRef.current && inputRef.current.value]);

        const css = classNames(`form-input ${className}`, {
            error: (error && error.message) || (className && className.includes('error')),
            touched: floatLable && (state.isFocused || !state.isEmpty || touched)
        });

        const labelCss = classNames('input-label', {
            touched: state.isFocused || !state.isEmpty || touched
        });

        const svgCss = classNames('input-icon', {
            touched: floatLable && (state.isFocused || !state.isEmpty || touched)
        });

        const svgCallback = () => {
            if (SvgCallback) SvgCallback();
        };

        return (
            <div className="input-group">
                <div className={css}>
                    <input
                        ref={inputRef}
                        placeholder={!floatLable ? placeholder : ''}
                        {...props}
                        onFocus={() => {
                            setState({ ...state, isFocused: true });
                            if (onFocusCallback) onFocusCallback();
                        }}
                        onBlur={() => setState({ ...state, isFocused: false })}
                        onChange={(e) => {
                            setState({ ...state, isEmpty: e.target.value.length === 0 });
                            if (onChange) {
                                onChange(e);
                            }
                        }}
                    />
                    {Svg && <Icon className={svgCss} Svg={Svg} size={14} onClick={svgCallback} />}
                    {floatLable && placeholder && (
                        <span className={labelCss}>
                            {placeholder}
                            {inputRef.current && inputRef.current.required ? ' *' : ''}
                        </span>
                    )}
                </div>
                {error && <span className="error-message">{error.message}</span>}
            </div>
        );
    }
);

Input.displayName = 'Input';
export default Input;
