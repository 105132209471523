import { AuthResponse } from '@api/models/view-models/response/auth-response';
import { IRootState } from '@store/store';
import { use } from 'i18next';

export class AuthHelpers {
    getAuthUser(): AuthResponse | undefined {
        const localAuth = localStorage.getItem('auth');
        let auth: AuthResponse | undefined;
        if (localAuth) {
            auth = JSON.parse(localAuth);
        }
        return auth;
    }

    setAuthUser(state: IRootState) {
        localStorage.setItem('auth', JSON.stringify(state.auth.authData));
    }

    getAuthToken(): string | undefined {
        const user = this.getAuthUser();
        return user?.jwtToken;
    }
}

export const authHelpers = new AuthHelpers();
