import { Block } from '@components/layout/block/block.layout';
import { withSettings } from '@shared/hoc/withSettings';
import React from 'react';
import { t } from 'i18next';
import { Link, useParams } from 'react-router-dom';
import ApiContext from '@api/api.context';
import { Carousel } from '@components/carousel/carousel';
import { getImageSrc } from '@utils/image.utils';
import moment from 'moment';
import { GetEnumValue } from '@utils/enum.utils';
import { ProjectStatusEnum } from '@api/models/enums/project-status-enum.enum';
import { ReactComponent as tag } from '@assets/icons/tag.svg';
import { Icon } from '@ui-kit/icon.ui';
import { ReactComponent as arrowLeft } from '@assets/icons/arrow-left.svg';
import { ProjectSingleModel } from '@api/models/view-models/crud/project/project-single-model';

interface Props {
    // props
}

export const ProjectComponent: React.FC<Props> = () => {
    const [state, setState] = React.useState<{
        project: ProjectSingleModel | undefined;
        loader: boolean;
    }>({
        project: undefined,
        loader: true
    });
    const { id } = useParams();
    const { projects } = React.useContext(ApiContext);

    React.useEffect(() => {
        if (id) {
            projects
                .getDetailProject(+id)
                .then((result) => {
                    setState({ ...state, project: result, loader: false });
                })
                .catch(() => setState({ ...state, loader: false }));
        }
    }, []);

    return (
        <>
            {state.loader ? (
                <Block title={t('common.loading')}>
                    <div className="loader" />
                </Block>
            ) : (
                state.project && (
                    <Block title={`${state.project.title} (${state.project.category})`}>
                        <Carousel>
                            {state.project.images.map((v) => {
                                return <img key={v} src={getImageSrc(v)} />;
                            })}
                        </Carousel>
                        <div className="proj-description">{state.project.description}</div>
                        <p className="date">
                            {moment(state.project.startDateTime).utc().format('DD MMM yyyy')} -{' '}
                            {state.project.endDateTime
                                ? moment(state.project.endDateTime).utc().format('DD MMM yyyy')
                                : t('pages.projects.present')}{' '}
                            <span>
                                (
                                {t(
                                    `pages.projects.statuses.${GetEnumValue(
                                        state.project.projectStatusEnum,
                                        ProjectStatusEnum
                                    )}`
                                )}
                                )
                            </span>
                        </p>
                        <div className="tags">
                            <Icon Svg={tag} size={24} fill={'var(--color-primary)'} />
                            {state.project.tags.map((tag, index) => {
                                return <span key={index}>{tag}</span>;
                            })}
                        </div>
                        <div className="back">
                            <Link to={`../`}>
                                <button className="btn primary">
                                    <Icon Svg={arrowLeft} size={16} fill={'#fff'} />
                                    {t('common.back')}
                                </button>
                            </Link>
                        </div>
                    </Block>
                )
            )}
        </>
    );
};

export const Project = withSettings(ProjectComponent);
