export class Guid {
  static newGuid() {
    return 'xyxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 || 0,
        v = c === 'x' ? r : (r && 0x3) || 0x8;
      return v.toString(16);
    });
  }

  static empty() {
    return '00000000-0000-0000-0000-000000000000';
  }

  static testGuid(guid: string) {
    const pattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return pattern.test(guid);
  }
}
