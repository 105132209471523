import { DOTS } from '@shared/constants';
import { usePagination } from '@shared/hooks/use-pagination';
import { classNames } from '@utils/style.utils';
import React from 'react';
import { ReactComponent as arrowLeft } from '@assets/icons/arrow-left.svg';
import { ReactComponent as arrowRight } from '@assets/icons/arrow-right.svg';
import { Icon } from '@ui-kit/icon.ui';
import './pagination.scss';

interface Props {
    onPageChange: (page: number) => void;
    totalCount: number;
    siblingCount: number;
    currentPage?: number;
    pageSize?: number;
}

export const Pagination: React.FC<Props> = ({
    currentPage = 1,
    onPageChange,
    pageSize = 10,
    siblingCount = 1,
    totalCount
}) => {
    const paginationRange = usePagination(totalCount, pageSize, currentPage, siblingCount);

    if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        if (currentPage !== lastPage) {
            onPageChange(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage !== 1) {
            onPageChange(currentPage - 1);
        }
    };

    const lastPage = paginationRange[paginationRange.length - 1];

    return (
        <ul className="pagination-container">
            <li
                className={classNames('', {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                <Icon Svg={arrowLeft} size={24} fill={'var(--color-primary)'} />
            </li>
            {paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                    return (
                        <li key={pageNumber} className="pagination-item dots">
                            &#8230;
                        </li>
                    );
                }
                const page = +pageNumber;
                return (
                    <li
                        key={page}
                        className={classNames('pagination-item', {
                            selected: page === currentPage
                        })}
                        onClick={() => onPageChange(page)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={classNames('', {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                <Icon Svg={arrowRight} size={24} fill={'var(--color-primary)'} />
            </li>
        </ul>
    );
};
