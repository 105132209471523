import { AuthResponse } from '@api/models/view-models/response/auth-response';
import { ActionsUnion, createAction } from '@store/action-helpers';

export const AUTH_LOGIN_START = '[AUTH] Login start';
export const AUTH_LOGIN_SUCCESS = '[AUTH] Login Success';
export const AUTH_LOGIN_FAILED = '[AUTH] Login Failed';
export const AUTH_REFRESH_TOKEN = '[AUTH] Refresh Token';
export const AUTH_LOGOUT = '[AUTH Logout]';

export const Actions = {
    loginStart: () => createAction(AUTH_LOGIN_START),
    loginSuccess: (response: AuthResponse) => createAction(AUTH_LOGIN_SUCCESS, response),
    loginFailed: (error: string) => createAction(AUTH_LOGIN_FAILED, error),
    refreshToken: (response: AuthResponse) => createAction(AUTH_REFRESH_TOKEN, response),
    logOut: () => createAction(AUTH_LOGOUT)
};

export type Actions = ActionsUnion<typeof Actions>;
