import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorStatus } from '@models/error.enum';
import { routePaths } from './routePaths';
import axiosInstance from '@api/axiosInstance';
import useToast from '@shared/hooks/use-toast';
import ApiContext from '@api/api.context';
import { AuthProps, withAuth } from '@shared/hoc/withAuth';
import axios from 'axios';
import { SettingsProps, withSettings } from '@shared/hoc/withSettings';
import { usePrevious } from '@shared/hooks/use-previous';

interface Props extends AuthProps, SettingsProps {}
let isRefreshing = false;
let subscribers: ((token?: string) => void)[] = [];

const InterceptorWrapperComponent: React.FC<Props> = ({
    children,
    authData,
    refreshToken,
    logout,
    isAutorized,
    currentLanguage
}) => {
    const [state, setState] = React.useState<{ currentRequestUse: number | undefined }>({
        currentRequestUse: undefined
    });
    const { auth } = React.useContext(ApiContext);
    const navigate = useNavigate();
    const { show } = useToast();
    const prevLanguage = usePrevious(currentLanguage);

    const onRefreshed = (token?: string) => {
        subscribers.map((cb) => cb(token));
        subscribers = [];
    };

    const subscribeTokenRefresh = (cb: (token?: string) => void) => {
        subscribers.push(cb);
    };

    React.useEffect(() => {
        if (prevLanguage !== undefined) {
            requestUse(currentLanguage);
        }
    }, [currentLanguage]);

    const requestUse = (lng: string): void => {
        if (state.currentRequestUse !== undefined) {
            axiosInstance.interceptors.request.eject(state.currentRequestUse);
        }
        const requestUse = axiosInstance.interceptors.request.use((request) => {
            if (request.withCredentials && request.headers && isAutorized && authData) {
                request.headers['Authorization'] = `Bearer ${authData.jwtToken}`;
            }
            if (lng === 'ru' && request.url && !request.url.includes('culture')) {
                if (request.url.includes('?')) {
                    request.url = request.url + '&culture=ru-RU';
                } else {
                    request.url = request.url + '?culture=ru-RU';
                }
            }
            return request;
        });
        setState({ ...state, currentRequestUse: requestUse });
    };

    React.useEffect(() => {
        requestUse(currentLanguage);
        axiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const originalRequest = error.config;
                switch (error.response?.status) {
                    case ErrorStatus.AccessDenided:
                        logout(() => navigate(routePaths.login));
                        break;
                    case ErrorStatus.Unauthorized:
                        if (!isRefreshing && authData) {
                            isRefreshing = true;
                            auth.refreshToken({
                                refreshToken: authData.refreshToken
                            })
                                .then((response) => {
                                    refreshToken(response);
                                    onRefreshed(response.jwtToken);
                                })
                                .catch(() =>
                                    logout(() => {
                                        navigate(routePaths.login);
                                        return onRefreshed();
                                    })
                                )
                                .finally(() => (isRefreshing = false));
                        }
                        return new Promise((resolve, reject) => {
                            subscribeTokenRefresh((token?: string) => {
                                if (token) {
                                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                                    if (
                                        originalRequest.baseURL &&
                                        originalRequest.url.startsWith(originalRequest.baseURL)
                                    ) {
                                        originalRequest.url = originalRequest.url.substring(
                                            originalRequest.baseURL.length
                                        );
                                    }
                                    resolve(
                                        axios(originalRequest).catch((error) => {
                                            if (error.response.status === 401) {
                                                logout(() => navigate(routePaths.login));
                                            }
                                        })
                                    );
                                } else {
                                    reject();
                                }
                            });
                        });
                    case ErrorStatus.BadRequest:
                    case ErrorStatus.BackendError:
                        show({
                            message: error.response.data.Message,
                            title: 'Error',
                            type: 'warning',
                            timeOut: 5000
                        });
                        break;
                    default:
                        show({
                            message: (error.response && error.response.data.Message) || error.message,
                            type: 'warning',
                            title: 'Error',
                            timeOut: 5000
                        });
                        break;
                }
                return Promise.reject(error);
            }
        );
    }, [authData]);

    return <>{children}</>;
};

export const InterceptorWrapper = withSettings(withAuth(InterceptorWrapperComponent));
