import { ComponentType } from 'react';
import { IRootState } from '@store/store';
import { Thunks as mainThunks } from '@store/auth/auth.thunk';
import { connect } from 'react-redux';
import { IAuthState } from '@store/auth/auth.reducer';
import { selectAuthenticated, selectIsAdmin } from '@store/auth/auth.selector';

interface StateProps extends IAuthState {
    isAutorized: boolean;
    isAdmin: boolean;
}

type DispatchThunksProps = typeof mainThunks;

interface DispatchProps extends DispatchThunksProps {}

export interface AuthProps extends StateProps, DispatchProps {}

export const withAuth = <OriginalProps extends object>(component: ComponentType<OriginalProps & AuthProps>) => {
    type Props = Omit<OriginalProps, keyof AuthProps>;
    const mapStateToProps = (state: IRootState): StateProps => {
        return {
            ...state.auth,
            // authData: state.auth.authData,
            // loading: state.auth.loading,
            // error: state.auth.error,
            isAdmin: selectIsAdmin(state),
            isAutorized: selectAuthenticated(state)
        };
    };

    return connect(mapStateToProps, {
        ...mainThunks
    })(component as any) as ComponentType<Props>;
};
