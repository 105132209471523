import React from 'react';
import ToastContext from '@components/toast/toast-context';
import { createPortal } from 'react-dom';
import { Toast } from '@components/toast/toast.component';
import { ToastModel, ToastModelExtended } from '@components/toast/toast-model';
import { Guid } from '@utils/uuid.utils';

const withToasts = (Component: React.FC) => {
  function WithToasts(props: any) {
    const [toastList, setToastList] = React.useState<ToastModelExtended[]>([]);

    const show = (toast: ToastModel) => {
      const newToast: ToastModelExtended = {
        id: Guid.newGuid(),
        message: toast.message,
        title: toast.title,
        type: toast.type,
        timeOut: toast.timeOut,
      };
      setToastList([...toastList, newToast]);
    };

    const toastModel = React.useMemo(() => {
      return {
        show: show,
      };
    }, [toastList]);

    const remove = (id: Guid) =>
      setToastList(toastList.filter((t) => t.id !== id));

    return (
      <ToastContext.Provider value={toastModel}>
        <Component {...props} />
        {toastList.length > 0 &&
          createPortal(
            <div className="toasts-wrapper">
              {toastList.map((t) => (
                <Toast key={`${t.id}`} toast={t} onClose={remove} />
              ))}
            </div>,
            document.body
          )}
      </ToastContext.Provider>
    );
  }

  return WithToasts;
};

export default withToasts;
