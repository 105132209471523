import { ProjectAddEdit } from '@pages/projects/project-add-edit.page';
import { Project } from '@pages/projects/project.page';
import { Projects } from '@pages/projects/projects.page';
import { lazy } from 'react';
import { routePaths } from './routePaths';

import { Config, RouteConfig } from './type';

export const generateRoutesFromConfigs = (config: Config[]): RouteConfig[] => {
    let allRoutes: RouteConfig[] = [];
    config.forEach((conf) => {
        const r = conf.routes.map((c) => {
            return { ...c, authRequire: c.authRequire || conf.authRequire || false };
        });
        allRoutes = [...allRoutes, ...r];
    });
    return allRoutes;
};

const routeConfig: Config[] = [
    {
        routes: [
            {
                path: routePaths.home,
                component: lazy(() => import('@pages/home/home.page'))
            },
            {
                path: routePaths.login,
                component: lazy(() => import('@pages/login/login.page').then(({ Login }) => ({ default: Login })))
            },
            {
                path: routePaths.education,
                component: lazy(() =>
                    import('@pages/education/education.page').then(({ Education }) => ({ default: Education }))
                )
            },
            {
                path: routePaths.job,
                component: lazy(() => import('@pages/job/job.page').then(({ Job }) => ({ default: Job })))
            },
            {
                path: routePaths.projects.root,
                component: lazy(() =>
                    import('@pages/projects/project.module').then(({ ProjectModule }) => ({ default: ProjectModule }))
                ),
                nestedRoutes: [
                    {
                        path: '',
                        component: Projects
                    },
                    {
                        path: routePaths.projects.project,
                        component: Project
                    },
                    {
                        path: routePaths.projects.add,
                        component: ProjectAddEdit,
                        authRequire: true
                    },
                    {
                        path: routePaths.projects.edit,
                        component: ProjectAddEdit,
                        authRequire: true
                    }
                ]
            },
            {
                path: '*',
                component: lazy(() => import('@pages/404/404.page').then(({ Page404 }) => ({ default: Page404 })))
            }
        ]
    }
];

const routes = [...generateRoutesFromConfigs(routeConfig)];

export default routes;
