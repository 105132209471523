export type Collection = {
    id: number;
    value: string;
};

export const GetEnumValue = (enumValue: number, enumType: any): string => {
    const valuesArray = Object.keys(enumType).reduce((acc: any[], curr, index, arr) => {
        if (index >= arr.length / 2) {
            acc.push(curr);
        }
        return acc;
    }, []);
    return valuesArray[enumValue];
};

export const GetCollectionFromEnum = (enumType: any): Collection[] => {
    const temp = Object.values(enumType);
    const lenght = temp.length / 2;
    const result: Collection[] = [];
    for (let index = 0; index < lenght; index++) {
        result.push({
            id: temp[lenght + index] as number,
            value: (temp[index] as string).split(/(?=[A-Z])/).join(' ')
        });
    }

    return result;
};
