import React from 'react';
import { t } from 'i18next';
import { Block } from '@components/layout/block/block.layout';
import { useNavigate, useParams } from 'react-router-dom';
import ApiContext from '@api/api.context';
import { ProjectCreateModel } from '@api/models/view-models/crud/project/project-create-model';
import { object, SchemaOf, string, date, number, array } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '@components/input/input.component';
import { ProjectEditModel } from '@api/models/view-models/crud/project/project-edit-model';
import './project-add-edit.style.scss';
import { Button } from '@components/button/button.component';
import TextArea from '@components/textarea/textarea';
import MultiSelect from '@components/multi-select/multi-select.component';
import { FilterOperation } from '@api/models/enums/filter-operation.enum';
import { ListTypeEnum } from '@api/models/enums/list-type-enum.enum';
import { ListItemListModel } from '@api/models/view-models/crud/list-item/list-item-list-model';
import { routePaths } from '@routes/routePaths';
import Select from '@components/select/select.component';
import { Collection, GetCollectionFromEnum } from '@utils/enum.utils';
import { ProjectStatusEnum } from '@api/models/enums/project-status-enum.enum';
import { DateTime } from '@components/datetime/datetime.component';

interface Props {
    // props
}

const params = {
    titleEN: string().required('Title (En) is required'),
    titleRU: string().required('Title (Ru) is required'),
    descriptionEN: string().required('Description (En) is required'),
    descriptionRU: string().required('Description (Ru) is required'),
    startDateTime: date().required('Start date is required'),
    endDateTime: date(),
    categoryId: number().required('Category is required'),
    tags: array().required('Field is required').min(1, 'Choose at least one'),
    projectStatusEnum: number().required('Project status is required')
};

const schemeCreate: SchemaOf<ProjectCreateModel> = object({ ...params });
const schemeEdit: SchemaOf<ProjectEditModel> = object({
    ...params,
    id: number().required(),
    xmin: number().required()
});

export const ProjectAddEdit: React.FC<Props> = () => {
    const { id } = useParams();
    const { projects, list } = React.useContext(ApiContext);
    const navigate = useNavigate();

    const [state, setState] = React.useState<{ loading: boolean }>({
        loading: false
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset
    } = useForm({
        resolver: yupResolver(id ? schemeEdit : schemeCreate)
    });

    const onSubmit = (data: any) => {
        setState({ ...state, loading: true });
        projects
            .addProject(data)
            .then((id) => {
                navigate('../project/edit/' + id);
            })
            .finally(() => setState({ ...state, loading: false }));
    };

    React.useEffect(() => {
        if (id) {
            setState({ ...state, loading: true });
            projects
                .getProject(+id)
                .then((result) => {
                    result.startDateTime = new Date(result.startDateTime);
                    reset(result);
                })
                .finally(() => setState({ ...state, loading: false }));
        }
    }, []);

    return (
        <Block title={id ? t('pages.projects.edit') : t('pages.projects.add')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={state.loading}>
                    <div className="form-group">
                        <div className="en-column">
                            <Input {...register('titleEN')} placeholder="Title (Eng)" error={errors.titleEN} />
                            <TextArea
                                {...register('descriptionEN')}
                                placeholder="Description (Eng)"
                                error={errors.descriptionEN}
                                rows={6}
                            />
                            <DateTime date={undefined} onDateChanged={(dt: Date) => {}} />
                            <Input
                                type={'date'}
                                {...register('startDateTime')}
                                placeholder="Start Date"
                                error={errors.startDateTime}
                            />
                            <Controller
                                name="tags"
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <MultiSelect<ListItemListModel, number>
                                            placeholder={'Tags'}
                                            request={list.getList}
                                            onSelected={(data) =>
                                                setValue(field.name, data, {
                                                    shouldValidate: true
                                                })
                                            }
                                            error={fieldState.error}
                                            selected={field.value}
                                            filter={{
                                                sort: [],
                                                query: [
                                                    {
                                                        field: 'listTypeId',
                                                        operation: FilterOperation.EqualsOr,
                                                        values: [ListTypeEnum.Tags]
                                                    }
                                                ]
                                            }}
                                            showItemsCount={3}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="projectStatusEnum"
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <Select<Collection, number>
                                            placeholder={'Project status'}
                                            data={GetCollectionFromEnum(ProjectStatusEnum)}
                                            textField="value"
                                            selected={field.value}
                                            onSelected={(data) =>
                                                setValue(field.name, data, {
                                                    shouldValidate: true
                                                })
                                            }
                                            error={fieldState.error}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="ru-column">
                            <Input {...register('titleRU')} placeholder="Title (Ru)" error={errors.titleRU} />
                            <TextArea
                                {...register('descriptionRU')}
                                placeholder="Description (Ru)"
                                error={errors.descriptionEN}
                                rows={6}
                            />
                            <Input
                                type={'date'}
                                {...register('endDateTime')}
                                placeholder="End Date"
                                error={errors.endDateTime}
                            />
                            <Controller
                                name="categoryId"
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <Select<ListItemListModel, number>
                                            placeholder={'Category'}
                                            request={list.getList}
                                            selected={field.value}
                                            onSelected={(data) =>
                                                setValue(field.name, data, {
                                                    shouldValidate: true
                                                })
                                            }
                                            error={fieldState.error}
                                            filter={{
                                                sort: [],
                                                query: [
                                                    {
                                                        field: 'listTypeId',
                                                        operation: FilterOperation.EqualsOr,
                                                        values: [ListTypeEnum.Categories]
                                                    }
                                                ]
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-action">
                        <Button type="submit" className="primary" title="Save" />
                        <Button title="Cancel" onClick={() => navigate('/' + routePaths.projects.root)} />
                    </div>
                </fieldset>
            </form>
        </Block>
    );
};
