import React, { TextareaHTMLAttributes } from 'react';
import { useForwardRef } from '@shared/hooks/use-forward-ref';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { classNames } from '@utils/style.utils';
import './textarea.style.scss';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

const TextArea: React.FC<Props> = React.forwardRef<HTMLTextAreaElement, Props>(
    ({ error, placeholder, onChange, value, ...props }, ref) => {
        const inputRef = useForwardRef<HTMLTextAreaElement>(ref);

        const [state, setState] = React.useState<{
            isFocused: boolean;
            isEmpty: boolean;
        }>({
            isEmpty: true,
            isFocused: false
        });

        const css = classNames('form-input', {
            error: error && error.message,
            touched: state.isFocused || !state.isEmpty
        });

        const labelCss = classNames('textarea-label', {
            touched: state.isFocused || !state.isEmpty
        });

        React.useEffect(() => {
            if (inputRef && inputRef.current) {
                setState({ ...state, isEmpty: inputRef.current.value.length === 0 });
            }
        }, [inputRef.current && inputRef.current.value]);

        return (
            <div className="input-group">
                <div className={css}>
                    <textarea
                        ref={inputRef}
                        {...props}
                        onFocus={() => setState({ ...state, isFocused: true })}
                        onBlur={() => setState({ ...state, isFocused: false })}
                        onChange={(e) => {
                            setState({ ...state, isEmpty: e.target.value.length === 0 });
                            if (onChange) {
                                onChange(e);
                            }
                        }}
                    />
                    {placeholder && <span className={labelCss}>{placeholder}</span>}
                </div>
                {error && <span className="error-message">{error.message}</span>}
            </div>
        );
    }
);

TextArea.displayName = 'TextArea';
export default TextArea;
