import { ActionsUnion } from '@store/action-helpers';
import { DispatchThunk, IRootState } from '@store/store';
import { Actions } from './settings.action';
import { settingsHelpers } from './settings.helpers';

export const Thunks = {
    changeLanguage: (lng: string) => {
        return (dispatch: DispatchThunk, getState: () => IRootState) => {
            dispatch(Actions.changeLanguage(lng));
            settingsHelpers.storeSettings(getState().settings);
        };
    }
};

export type Thunks = ActionsUnion<typeof Thunks>;
