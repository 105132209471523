import { CancelToken } from 'axios';
import { BaseApi } from './api-base';
import { ApiPath } from './api.path';
import { IApi } from './contracts.api';
import { PageFilterViewModel } from './models/view-models/base/page-filter-view-model';
import { PageResultViewModel } from './models/view-models/base/page-result-view-model';
import { ListItemListModel } from './models/view-models/crud/list-item/list-item-list-model';
import { ProjectListModel } from './models/view-models/crud/project/project-list-model';
import { ProjectSingleModel } from './models/view-models/crud/project/project-single-model';
import { AuthRequest } from './models/view-models/request/auth-request';
import { RefreshTokenRequest } from './models/view-models/request/refresh-token-request';
import { AuthResponse } from './models/view-models/response/auth-response';
import { ProjectCreateModel } from './models/view-models/crud/project/project-create-model';
import { request } from 'http';
import { ProjectEditModel } from './models/view-models/crud/project/project-edit-model';

class Api extends BaseApi implements IApi {
    auth = {
        signIn: async (model: AuthRequest): Promise<AuthResponse> => {
            return this.post(ApiPath.auth.signIn, model);
        },
        refreshToken: async (model: RefreshTokenRequest): Promise<AuthResponse> => {
            return this.post(ApiPath.auth.refresh, model, true);
        },
        signOut: async (): Promise<null> => {
            return this.post(ApiPath.auth.signOut, null, true);
        },
        getAuth: async (): Promise<AuthResponse> => {
            return this.get(ApiPath.auth.getAuth);
        }
    };
    projects = {
        getProjectList: async (request: PageFilterViewModel): Promise<PageResultViewModel<ProjectListModel>> => {
            return this.post(ApiPath.projects.list, request);
        },
        getDetailProject: async (id: number): Promise<ProjectSingleModel> => {
            return this.get(`${ApiPath.projects.get}${id}`);
        },
        addProject: async (request: ProjectCreateModel): Promise<number> => {
            return this.post(ApiPath.projects.get, request, true);
        },
        getProject: async (id: number): Promise<ProjectEditModel> => {
            return this.get(`${ApiPath.projects.getEdit}${id}`, true);
        }
    };
    list = {
        getList: async (requset: PageFilterViewModel): Promise<PageResultViewModel<ListItemListModel>> => {
            return this.post(ApiPath.list.list, requset, true);
        }
    };
}

const instanceApi = new Api();
export default instanceApi;
