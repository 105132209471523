import React, { useRef } from 'react';

export interface Props {
    ClickOutsideEvent: () => void;
}

export default function useClickOutside(callback: () => void) {
    const refClickOutside = useRef<any>(null);

    const handleClickOutside = (event: Event) => {
        if (refClickOutside.current && !refClickOutside.current.contains(event.target as Node)) {
            callback();
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { refClickOutside };
}
