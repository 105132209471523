import React from 'react';
import './header.style.scss';
import { t } from 'i18next';
import { ReactComponent as telegram } from '@assets/icons/telegram.svg';
import { ReactComponent as whatsapp } from '@assets/icons/whatsapp.svg';
import { ReactComponent as skype } from '@assets/icons/skype.svg';
import { ReactComponent as vk } from '@assets/icons/vk.svg';
import { ReactComponent as calendar } from '@assets/icons/calendar.svg';
import { ReactComponent as phone } from '@assets/icons/phone.svg';
import { ReactComponent as email } from '@assets/icons/email.svg';
import { ReactComponent as location } from '@assets/icons/location.svg';
import { ReactComponent as ru } from '@assets/icons/ru.svg';
import { ReactComponent as en } from '@assets/icons/en.svg';
import { ReactComponent as clock } from '@assets/icons/clock.svg';
import { Icon } from '@ui-kit/icon.ui';
import { SettingsProps, withSettings } from '@shared/hoc/withSettings';
import i18n from '@utils/i18n';
import { TypeAnimation } from '@components/type-animation/type-animation';
import { classNames } from '@utils/style.utils';
import { CurrentTime } from '@components/currentTime/currentTime';
import { ReactComponent as hello } from '@assets/icons/hello.svg';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { size } from '@shared/constants';

interface Props extends SettingsProps {}

const HeaderComponent: React.FC<Props> = ({ currentLanguage, changeLanguage }) => {
    const changeLanguageHandler = (lng: string) => {
        i18n.changeLanguage(lng);
        changeLanguage(lng);
    };

    const lngCss = classNames('', {
        ru: currentLanguage === 'ru'
    });

    const h1tablet = classNames('mobile', {
        ru: currentLanguage === 'ru'
    });

    const words = [t('header.job'), t('header.job1'), t('header.job2'), t('header.job3')];

    const mobile = useMediaQuery({ query: `(max-width: ${size.MAX_MOBILE_WIDTH}px)` });

    const getSocial = () => {
        return (
            <div className="social">
                <a target={'_blank'} href="https://t.me/frydex">
                    <Icon Svg={telegram} size={mobile ? 24 : 32} />
                </a>
                <a target={'_blank'} href="https://wa.me/79143421134">
                    <Icon Svg={whatsapp} size={mobile ? 24 : 32} />
                </a>
                <a target={'_blank'} href="https://vk.com/darth_venom">
                    <Icon Svg={vk} size={mobile ? 24 : 32} />
                </a>
                <div className="v-divider" />
                <Icon
                    className="language"
                    Svg={currentLanguage === 'en' ? en : ru}
                    width={24}
                    height={12.6}
                    onClick={() => changeLanguageHandler(currentLanguage === 'en' ? 'ru' : 'en')}
                />
            </div>
        );
    };

    const getContact = () => {
        const cssContacts = `header-contacts${mobile ? '-mobile' : ''}`;
        return (
            <div className={cssContacts}>
                <span>
                    <Icon Svg={calendar} size={mobile ? 24 : 32} fill="var(--color-primary)" />
                    <p>08.03.1987</p>
                </span>
                <a href="tel:+79143421134">
                    <Icon Svg={phone} size={mobile ? 24 : 32} fill="var(--color-primary)" />
                    <p>+7 (914) 34-211-34</p>
                </a>
                <a href="mailto:admin@frydex.com">
                    <Icon Svg={email} size={mobile ? 24 : 32} fill="var(--color-primary)" />
                    <p>admin@frydex.com</p>
                </a>
                <a target={'_blank'} href="https://goo.gl/maps/4qsdwUEsBMeX6SdG8">
                    <Icon Svg={location} size={mobile ? 24 : 32} fill="var(--color-primary)" />
                    <p>{t('header.city')}</p>
                </a>
                {/* <span>
                    <Icon Svg={clock} size={mobile ? 24 : 32} fill="var(--color-primary)" />
                    <CurrentTime />
                </span> */}
            </div>
        );
    };

    return (
        <>
            <MediaQuery maxWidth={size.MAX_MOBILE_WIDTH}>
                <div className="header-mobile">
                    <div className="header-info">
                        <h2 className={lngCss}>
                            {t('header.hello')} <Icon Svg={hello} size={32} />
                        </h2>
                        <h1 className={lngCss}>{t('header.myself')}</h1>
                        <h1 className={h1tablet}>
                            {t('header.iam')}
                            <TypeAnimation contents={words} delay={100} repeatDelay={3000} />
                        </h1>
                        <hr />
                    </div>
                    <div className="header-photo-mobile">
                        <img src="/images/profile.webp" />
                        {getSocial()}
                        {getContact()}
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={size.MAX_MOBILE_WIDTH + 1}>
                <div className="header">
                    <div className="header-photo">
                        <img src="/images/profile.webp" />
                        {getSocial()}
                    </div>
                    <div className="header-info">
                        <h2 className={lngCss}>
                            {t('header.hello')} <Icon Svg={hello} size={32} />
                        </h2>
                        <h1 className={lngCss}>{t('header.myself')}</h1>
                        <h1 className={h1tablet}>
                            {t('header.iam')}
                            <TypeAnimation contents={words} delay={100} repeatDelay={3000} />
                        </h1>
                        <hr />
                        {getContact()}
                    </div>
                </div>
            </MediaQuery>
        </>
    );
};

export const Header = withSettings(HeaderComponent);
