import { RoleEnum } from '@api/models/enums/role-enum.enum';
import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '@store/store';

export const getAuth = (state: IRootState) => state.auth;

export const selectAuthenticated = createSelector(getAuth, (authState) => {
    return authState.authData !== undefined && authState.authData.user !== undefined;
});

export const selectIsAdmin = createSelector(getAuth, (authState) => {
    return (
        authState.authData !== undefined &&
        authState.authData.user !== undefined &&
        authState.authData.user.roles.some((role: RoleEnum) => role === RoleEnum.Admin)
    );
});
