import { Block } from '@components/layout/block/block.layout';
import { routePaths } from '@routes/routePaths';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './404.style.scss';
import { t } from 'i18next';
import { SettingsProps, withSettings } from '@shared/hoc/withSettings';
import { classNames } from '@utils/style.utils';

interface Props extends SettingsProps {}

const Page404Component: React.FC<Props> = ({ currentLanguage }) => {
    const lngCss = classNames('', {
        ru: currentLanguage === 'ru'
    });

    return (
        <Block title={t('pages.error.title')}>
            <div className="error-page">
                <div className="not-found">
                    <img src="/images/404.webp" />
                    <h1 className={lngCss}>{t('pages.error.desc')}</h1>
                    <NavLink to={routePaths.home}>{t('pages.error.gohome')}</NavLink>
                </div>
            </div>
        </Block>
    );
};

export const Page404 = withSettings(Page404Component);
