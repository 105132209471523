import { authHelpers } from '@store/auth/auth.helpers';
import { CancelToken } from 'axios';
import axios from './axiosInstance';
import { CustomResponse } from './models/view-models/base/custom-response';

export class BaseApi {
    async get<R>(api: string, withCredentials = false, cancelToken?: CancelToken): Promise<R> {
        const response = await axios.get<CustomResponse<R>>(api, {
            withCredentials: withCredentials,
            cancelToken: cancelToken
        });
        return response && response.data && response.data.payload;
    }

    async post<T, R>(
        api: string,
        model: T | undefined = undefined,
        withCredentials = false,
        cancelToken?: CancelToken
    ): Promise<R> {
        const response = await axios.post<CustomResponse<R>>(api, model, {
            withCredentials: withCredentials,
            cancelToken: cancelToken
        });
        return response && response.data && response.data.payload;
    }
}
