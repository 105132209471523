import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@assets/localization/en.json';
import ru from '@assets/localization/ru.json';
import { settingsHelpers } from '@store/settings/settings.helpers';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en
        },
        ru: {
            translation: ru
        }
    },
    ns: ['translation'],
    defaultNS: 'translation',
    load: 'all',
    supportedLngs: ['en', 'ru'],
    lng: settingsHelpers.loadSettings().currentLanguage,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
