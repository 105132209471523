/* eslint-disable @typescript-eslint/no-explicit-any */
export const classNames = (...args: any[]) => {
  const classes: any[] = [];

  for (const arg of args) {
    if (!arg) {
      continue;
    }

    const argType = typeof arg;

    if (argType === 'string') {
      classes.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      // eslint-disable-next-line prefer-spread
      const inner = classNames.apply(null, arg);
      if (inner) {
        classes.push(inner);
      }
    } else if (argType === 'object') {
      for (const key in arg) {
        // eslint-disable-next-line no-prototype-builtins
        if (arg.hasOwnProperty(key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  const result = classes.join(' ');
  return result || undefined;
};