export enum FilterOperation {
    Equals = 0,
    EqualsOr = 1,
    NotEquals = 2,
    ContainsAnd = 3,
    ContainsOr = 4,
    NotContains = 5,
    GreaterThan = 6,
    GreaterThanOrEqual = 7,
    LessThan = 8,
    LessThanOrEqual = 9,
    ContainsCollection = 10,
    NotContainsCollection = 11
}
