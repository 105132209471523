import React from 'react';
import './footer.style.scss';

interface Props {
    // props
}

export const Footer: React.FC<Props> = () => {
    const year = new Date().getFullYear();
    return (
        <div className="footer">
            <hr />
            <div className="clients">
                <div>
                    <a target={'_blank'} href="https://genolis.com.au">
                        <img src="/images/genolis_w.webp" />
                    </a>
                    <a target={'_blank'} href="https://wave-access.com">
                        <img src="/images/waveaccess_w.webp" />
                    </a>
                    <a target={'_blank'} href="https://dns-shop.ru">
                        <img src="/images/dns_w.webp" />
                    </a>
                    <a target={'_blank'} href="http://siabook.ru">
                        <img src="/images/sia_w.webp" />
                    </a>
                    <a target={'_blank'} href="https://www.vl.ru/ekker">
                        <img src="/images/ekker_w.webp" />
                    </a>
                    <a target={'_blank'} href="http://primtep.ru">
                        <img src="/images/primteplo_w.webp" />
                    </a>
                    <a target={'_blank'} href="https://alternadv.com">
                        <img src="/images/alterna_w.webp" />
                    </a>
                    <a target={'_blank'} href="https://pskb.com">
                        <img src="/images/pskb_w.webp" />
                    </a>
                </div>
            </div>
            <div className="copyright">
                <span>Copyright {year} © — Frydex Inc. All right reserved.</span>
                <span>
                    <a target={'_blank'} href="https://t.me/frydex">
                        Telegram
                    </a>
                    <a target={'_blank'} href="https://wa.me/79143421134">
                        Whatsapp
                    </a>
                    <a target={'_blank'} href="https://vk.com/darth_venom">
                        VKontakte
                    </a>
                </span>
            </div>
        </div>
    );
};
