import { ComponentType } from 'react';
import { IRootState } from '@store/store';
import { Thunks as mainThunks } from '@store/settings/settings.thunk';
import { ISettingState } from '@store/settings/settings.reducer';
import { connect } from 'react-redux';

interface StateProps extends ISettingState {}

type DispatchThunksProps = typeof mainThunks;

interface DispatchProps extends DispatchThunksProps {}

export interface SettingsProps extends StateProps, DispatchProps {}

export const withSettings = <OriginalProps extends object>(component: ComponentType<OriginalProps & SettingsProps>) => {
    type Props = Omit<OriginalProps, keyof SettingsProps>;
    const mapStateToProps = (state: IRootState): StateProps => {
        return {
            ...state.settings
        };
    };

    return connect(mapStateToProps, {
        ...mainThunks
    })(component as any) as ComponentType<Props>;
};
