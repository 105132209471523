import { AuthRequest } from '@api/models/view-models/request/auth-request';
import { ActionsUnion } from '@store/action-helpers';
import { DispatchThunk, IRootState } from '@store/store';
import { Actions } from '@store/auth/auth.action';
import { CustomResponse } from '@api/models/view-models/base/custom-response';
import { AuthResponse } from '@api/models/view-models/response/auth-response';
import { IAuthApi } from '@api/contracts.api';
import { authHelpers } from './auth.helpers';

export const Thunks = {
    signIn: (model: AuthRequest, successCallback: () => void) => {
        return (dispatch: DispatchThunk, getState: () => IRootState, api: IAuthApi) => {
            dispatch(Actions.loginStart());
            api.signIn(model)
                .then((response: AuthResponse) => {
                    dispatch(Actions.loginSuccess(response));
                    authHelpers.setAuthUser(getState());
                    successCallback();
                })
                .catch((error: CustomResponse<null>) => {
                    dispatch(Actions.loginFailed(error.message));
                });
        };
    },
    refreshToken: (model: AuthResponse) => {
        return (dispatch: DispatchThunk, getState: () => IRootState) => {
            dispatch(Actions.refreshToken(model));
            authHelpers.setAuthUser(getState());
        };
    },
    logout: (callBack: () => void) => {
        return (dispatch: DispatchThunk, getState: () => IRootState) => {
            dispatch(Actions.logOut());
            localStorage.clear();
            callBack();
        };
    }
};

export type Thunks = ActionsUnion<typeof Thunks>;
