import { Block } from '@components/layout/block/block.layout';
import React from 'react';
import moment from 'moment';
import { t } from 'i18next';
import ApiContext from '@api/api.context';
import { PageFilterViewModel } from '@api/models/view-models/base/page-filter-view-model';
import './projects.style.scss';
import { classNames } from '@utils/style.utils';
import { SettingsProps, withSettings } from '@shared/hoc/withSettings';
import { ProjectStatusEnum } from '@api/models/enums/project-status-enum.enum';
import { GetEnumValue } from '@utils/enum.utils';
import { getImageSrc } from '@utils/image.utils';
import { ReactComponent as tag } from '@assets/icons/tag.svg';
import { Icon } from '@ui-kit/icon.ui';
import { Link, NavLink } from 'react-router-dom';
import { Modal } from '@ui-kit/modal/modal.ui';
import { Pagination } from '@components/pagination/pagination';
import { usePrevious } from '@shared/hooks/use-previous';
import { ProjectListModel } from '@api/models/view-models/crud/project/project-list-model';
import { AuthProps, withAuth } from '@shared/hoc/withAuth';
import { Button } from '@components/button/button.component';
import { routePaths } from '@routes/routePaths';

interface Props extends SettingsProps, AuthProps {}

const ProjectsComponent: React.FC<Props> = ({ currentLanguage, isAdmin }) => {
    // const lngCss = classNames('', {
    //     ru: currentLanguage === 'ru'
    // });

    const { projects } = React.useContext(ApiContext);
    const [state, setState] = React.useState<{
        data: ProjectListModel[];
        total: number;
        isModalOpen: boolean;
        currentImage: string | undefined;
        loading: boolean;
    }>({
        data: [],
        total: 0,
        isModalOpen: false,
        currentImage: undefined,
        loading: true
    });
    const [filter, setFilter] = React.useState<PageFilterViewModel>({
        query: [],
        sort: [
            {
                field: 'StartDateTime',
                asc: false
            }
        ],
        page: 1,
        size: 6
    });
    const prevFilter = usePrevious(filter);
    const prevLanguage = usePrevious(currentLanguage);

    React.useEffect(() => {
        loadData();
    }, []);

    React.useEffect(() => {
        if (
            (prevFilter && JSON.stringify(prevFilter) !== JSON.stringify(filter)) ||
            (prevLanguage && currentLanguage !== prevLanguage)
        ) {
            loadData();
        }
    }, [filter, currentLanguage]);

    const closeModal = () => {
        setState({ ...state, currentImage: undefined, isModalOpen: false });
    };

    const renderImage = (id: string) => {
        const src = getImageSrc(id);
        return <img src={src} onClick={() => setState({ ...state, currentImage: src, isModalOpen: true })} />;
    };

    const loadData = () => {
        projects
            .getProjectList(filter)
            .then((result) => {
                setState({ ...state, data: result.data, total: result.totalCount, loading: false });
            })
            .catch(() => setState({ ...state, loading: false }));
    };

    const pageChanged = (page: number) => {
        setFilter({
            page,
            query: filter.query,
            size: filter.size,
            sort: filter.sort
        });
    };

    return (
        <Block title={t('pages.projects.proj')}>
            <div className="projects">
                {isAdmin && (
                    <NavLink to={routePaths.projects.add}>
                        <Button className="primary" title="Add Project" />
                    </NavLink>
                )}
                {state.loading && <div className="loader" />}
                {!state.loading && !state.data.length && <p className="not-found">{t('pages.projects.not-found')}</p>}
                {!state.loading &&
                    state.data &&
                    state.data.map((value: ProjectListModel) => {
                        const status = `pages.projects.statuses.${GetEnumValue(value.status, ProjectStatusEnum)}`;
                        const favImg = value.images.find((s) => s.isFavorite);
                        return (
                            <div key={value.id} className="project">
                                {favImg && renderImage(favImg.id)}
                                <Link to={`project/${value.id}`}>
                                    <h3>
                                        {value.title} ({value.category})
                                    </h3>
                                    <p className="descr">{value.description}</p>
                                </Link>
                                <div className="tags">
                                    <Icon Svg={tag} size={24} fill={'var(--color-primary)'} />
                                    {value.tags.map((tag, index) => {
                                        return <span key={index}>{tag}</span>;
                                    })}
                                </div>
                                <p className="date">
                                    <span>{t(status)}</span>
                                    {' - '}
                                    {moment(value.startDateTime).utc().format('DD MMM yyyy')} -{' '}
                                    {value.endDateTime
                                        ? moment(value.endDateTime).utc().format('DD MMM yyyy')
                                        : t('pages.projects.present')}{' '}
                                </p>
                            </div>
                        );
                    })}
            </div>
            {state.total > 0 && (
                <Pagination
                    currentPage={filter.page}
                    pageSize={filter.size}
                    totalCount={state.total}
                    onPageChange={pageChanged}
                    siblingCount={1}
                />
            )}
            <Modal isOpen={state.isModalOpen} onClose={closeModal} bordered={false}>
                {state.currentImage && <img className="modal-img" src={state.currentImage} />}
            </Modal>
        </Block>
    );
};

export const Projects = withAuth(withSettings(ProjectsComponent));
