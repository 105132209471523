import { Icon } from '@ui-kit/icon.ui';
import './button.style.scss';
import React, { ButtonHTMLAttributes } from 'react';
import { classNames } from '@utils/style.utils';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    iconData?: { icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; fill: string; size?: number };
    className?: string;
}

export const Button: React.FC<Props> = ({ title, iconData, className, ...props }: Props) => {
    if (iconData && !iconData.size) {
        iconData.size = 24;
    }

    const css = classNames(['btn', ...(className?.split(' ') ?? [])]);

    return (
        <button className={css} {...props}>
            {iconData && <Icon Svg={iconData.icon} size={iconData.size} fill={iconData.fill} />}
            {title}
        </button>
    );
};
