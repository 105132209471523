import { PageFilterViewModel } from '@api/models/view-models/base/page-filter-view-model';
import { PageResultViewModel } from '@api/models/view-models/base/page-result-view-model';
import React, { InputHTMLAttributes } from 'react';
import { ReactComponent as close } from '@assets/icons/close.svg';
import { ReactComponent as arrow_up } from '@assets/icons/arrow-up.svg';
import { ReactComponent as arrow_down } from '@assets/icons/arrow-down.svg';
import { ReactComponent as search } from '@assets/icons/search.svg';
import './multi-select.style.scss';
import { Icon } from '@ui-kit/icon.ui';
import Input from '@components/input/input.component';
import useClickOutside from '@shared/hooks/use-click-outside';
import { classNames } from '@utils/style.utils';
import { CheckBox } from '@components/checkbox/checkbox';
import { FilterOperation } from '@api/models/enums/filter-operation.enum';
import { useDebounce } from '@shared/hooks/use-debounce';
import { FieldError } from 'react-hook-form';
import { usePrevious } from '@shared/hooks/use-previous';

interface Props<T, TID> extends InputHTMLAttributes<HTMLInputElement> {
    textField?: string;
    valueField?: string;
    selected?: TID[];
    onLoadData?: () => Promise<PageResultViewModel<T>>;
    onSearchEvent?: (value: string | undefined) => void;
    request?: (request: PageFilterViewModel) => Promise<PageResultViewModel<T>>;
    filter?: PageFilterViewModel;
    data?: T[];
    showItemsCount?: number;
    onSelected?: (data: TID[]) => void;
    error?: FieldError | undefined;
}
const MultiSelect = <T, TID>({
    valueField = 'id',
    textField = 'name',
    selected = [],
    data,
    placeholder,
    onLoadData,
    showItemsCount = 2,
    onSearchEvent,
    request,
    filter,
    onSelected,
    name,
    error,
    ...props
}: Props<T, TID>) => {
    const { refClickOutside } = useClickOutside(() => setState({ ...state, expanded: false }));
    const [state, setState] = React.useState<{
        isLoading: boolean;
        isFocused: boolean;
        expanded: boolean;
        isEmpty: boolean;
        selected: TID[];
        data?: T[];
        filter?: PageFilterViewModel;
        search: string;
    }>({
        isLoading: true,
        isEmpty: true,
        isFocused: false,
        expanded: false,
        selected: [],
        data,
        filter,
        search: ''
    });
    const searchTerms = useDebounce(state.filter, 500);
    const prevSelect = usePrevious(selected);

    React.useEffect(() => {
        setState({ ...state, isLoading: true });
        if (data) {
            setState({ ...state, isLoading: false, data });
        }
    }, [state.filter]);

    React.useEffect(() => {
        if (selected && selected.length && state.data && state.data.length) {
            setState({ ...state, selected: selected });
        }
    }, [state.data, selected]);

    React.useEffect(() => {
        loadData();
    }, [searchTerms]);

    const loadData = () => {
        if (request && state.filter) {
            request(state.filter)
                .then((res) => {
                    setState({ ...state, data: res.data, isLoading: false });
                })
                .catch(() => setState({ ...state, isLoading: false }));
        }
    };

    const removeAll = () => {
        setState({ ...state, selected: [], expanded: false });
        if (onSelected) {
            onSelected([]);
        }
    };

    const removeItem = (id: TID) => {
        const index = state.selected.findIndex((x: any) => (x[valueField] as TID) === id);
        if (index > -1) {
            state.selected.splice(index, 1);
            setState({ ...state, selected: state.selected, expanded: false });
            if (onSelected) onSelected(state.selected.map((x: any) => x[valueField] as TID));
        }
    };

    const selectHandler = (id: TID, value: T) => {
        const index = state.selected.findIndex((x: any) => x === id);
        if (index > -1) {
            state.selected.splice(index, 1);
        } else {
            state.selected.push(id);
        }
        setState({ ...state, selected: state.selected });
        if (onSelected) onSelected(state.selected.map((x: any) => x));
    };

    const css = classNames('', {
        expanded: state.expanded,
        selected: state.selected.length,
        error: !state.expanded && error
    });

    const searhHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFilter = { ...state.filter } as PageFilterViewModel | undefined;
        if (event.target.value && event.target.value.length && newFilter) {
            const index = newFilter.query.findIndex((x) => x.field === textField);
            const value = {
                field: textField,
                operation: FilterOperation.ContainsAnd,
                values: [event.target.value]
            };
            if (index > -1) newFilter.query[index] = value;
            else newFilter.query.push(value);
            setState({ ...state, filter: newFilter, search: event.target.value });
        } else if (newFilter) {
            clearSearch(newFilter);
        }
    };

    const clearSearch = (newFilter: PageFilterViewModel | undefined) => {
        if (newFilter) {
            const index = newFilter.query.findIndex((x) => x.field === textField);
            if (index > -1) newFilter.query.splice(index, 1);
            setState({ ...state, filter: newFilter, search: '' });
        }
    };

    const getSelectedTag = (id: any) => {
        const tag = state.data && state.data.find((x: any) => id === x[valueField]);
        if (tag) {
            return (tag as any)[textField];
        }
        return undefined;
    };

    return (
        <div ref={refClickOutside} className="custom-multi-select">
            <Input
                touched={state.expanded || state.selected.length > 0}
                className={css}
                readOnly={true}
                placeholder={placeholder}
                onFocusCallback={() => setState({ ...state, expanded: true })}
                {...props}
            />
            <div className="tags">
                {state.selected.length <= showItemsCount && (
                    <>
                        {state.selected.map((id: any) => {
                            return (
                                <span className="tag" key={id}>
                                    {getSelectedTag(id)}
                                    <Icon className="close" size={14} Svg={close} onClick={() => removeItem(id)} />
                                </span>
                            );
                        })}
                    </>
                )}
                {state.selected.length > showItemsCount && (
                    <span className="tag">{state.selected.length} item selected</span>
                )}
            </div>
            {state.selected.length > 0 && <Icon className="clear" Svg={close} size={14} onClick={removeAll} />}
            {state.selected.length === 0 && (
                <Icon
                    className={css}
                    Svg={state.expanded ? arrow_up : arrow_down}
                    size={14}
                    onClick={() => {
                        const newFilter = { ...state.filter } as PageFilterViewModel | undefined;
                        clearSearch(newFilter);
                        setState({ ...state, expanded: !state.expanded });
                    }}
                />
            )}
            {!state.expanded && error && <span className="error-message">{error.message}</span>}
            {state.expanded && (
                <div className="select">
                    <div className="search">
                        <Input
                            Svg={state.search.length > 0 ? close : search}
                            SvgCallback={() => clearSearch(filter)}
                            value={state.search}
                            floatLable={false}
                            placeholder={'Search...'}
                            onChange={searhHandler}
                        />
                    </div>
                    <div className="items">
                        {state.data && state.data.length ? (
                            state.data.map((v: any) => {
                                return (
                                    <CheckBox
                                        key={v[valueField]}
                                        label={v[textField]}
                                        checked={state.selected.findIndex((r: any) => r === v[valueField]) > -1}
                                        onCheckChange={(checked) => selectHandler(v[valueField], v)}
                                    />
                                );
                            })
                        ) : (
                            <span className="no-data">No data</span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
