import React from 'react';
import './modal.style.scss';
import { ReactComponent as close } from '@assets/icons/close.svg';
import { Icon } from '@ui-kit/icon.ui';
import { classNames } from '@utils/style.utils';

interface Props {
    isOpen: boolean;
    bordered?: boolean;
    onClose: () => void;
}

export const Modal: React.FC<Props> = ({ isOpen, onClose, children, bordered = true }) => {
    React.useEffect(() => {
        changeClasses();
    }, [isOpen]);

    const changeClasses = () => {
        if (isOpen) {
            document.querySelector('#menu')?.classList.add('modal-open');
            document.body.style.overflow = 'hidden';
        } else {
            document.querySelector('#menu')?.classList.remove('modal-open');
            document.body.style.overflow = 'unset';
        }
    };

    const css = classNames('modal-window', {
        bordered
    });

    return (
        <>
            {isOpen && (
                <div className="modal">
                    <div className="blur" />
                    <div className={css}>
                        <Icon
                            className="close-btn"
                            Svg={close}
                            size={24}
                            fill={'var(--color-primary)'}
                            onClick={() => onClose()}
                        />
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};
