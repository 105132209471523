import { SettingsProps, withSettings } from '@shared/hoc/withSettings';
import { classNames } from '@utils/style.utils';
import React from 'react';
import './block.style.scss';

interface Props extends SettingsProps {
    title: string;
}

const BlockComponent: React.FC<Props> = ({
    children,
    title,
    currentLanguage
}) => {
    const lngCss = classNames('block-title', {
        'ru': currentLanguage === 'ru'
    });
    return (
        <div className='block'>
            <h1 className={lngCss}>{title}</h1>
            <div className='block-content'>
                {children}
            </div>
        </div>
    );
};

export const Block = withSettings(BlockComponent);