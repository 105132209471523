import React from 'react';
import './datetime.style.scss';
import {
    calendar,
    CALENDAR_MONTHS,
    getDateISO,
    getNextMonth,
    getPreviousMonth,
    isSameDay,
    isSameMonth,
    WEEK_DAYS
} from './datetime.helper';
import { classNames } from '@utils/style.utils';

interface Props {
    date: Date | undefined;
    onDateChanged: (date: Date) => void;
}

export const DateTime: React.FC<Props> = ({ date, onDateChanged }) => {
    let pressureTimer: NodeJS.Timeout, pressureTimeout: NodeJS.Timeout;
    const [dateState, setDateState] = React.useState<{
        current: Date | undefined;
        month: number;
        year: number;
        today: Date;
    }>({ current: undefined, month: 0, year: 0, today: new Date() });

    React.useEffect(() => {
        addDateToState(date);
    }, []);

    const addDateToState = (date: Date | undefined) => {
        const tempDate = date ? date : new Date();
        setDateState({
            ...dateState,
            current: date,
            month: tempDate.getMonth() + 1,
            year: tempDate.getFullYear()
        });
    };

    const getCalendarDates = (): string[][] => {
        const { current, month, year } = dateState;
        const calendarMonth = month || (current && current.getMonth() + 1);
        const calendarYear = year || (current && current.getFullYear());
        return calendar(calendarMonth, calendarYear);
    };

    const renderDayLabel = (day: string, index: number) => {
        const daylabel = WEEK_DAYS[day].toUpperCase();
        return (
            <div className="calendar-day" key={index}>
                {daylabel}
            </div>
        );
    };

    const renderMonthAndYear = () => {
        const { month, year } = dateState;
        const monthname = Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
        return (
            <div className="calendar-header">
                <div
                    className="calendar-arrow-left"
                    onMouseDown={handlePrevious}
                    onMouseUp={clearPressureTimer}
                    title="Previous Month"
                />
                <div className="calendar-month">
                    {monthname} {year}
                </div>
                <div
                    className="calendar-arrow-right"
                    onMouseDown={handleNext}
                    onMouseUp={clearPressureTimer}
                    title="Next Month"
                />
            </div>
        );
    };

    const gotoDate = (date: Date) => (evt: React.MouseEvent<HTMLElement>) => {
        evt && evt.preventDefault();
        const { current } = dateState;
        !(current && isSameDay(date, current)) && addDateToState(date);
        onDateChanged(date);
    };

    const handlePrevious = (evt: React.MouseEvent<HTMLElement>) => {
        evt && evt.preventDefault();
        const fn = evt.shiftKey ? gotoPreviousYear : gotoPreviousMonth;
        handlePressure(fn);
    };
    const handleNext = (evt: React.MouseEvent<HTMLElement>) => {
        evt && evt.preventDefault();
        const fn = evt.shiftKey ? gotoNextYear : gotoNextMonth;
        handlePressure(fn);
    };

    const gotoPreviousMonth = () => {
        const { month, year } = dateState;
        const previousMonth = getPreviousMonth(month, year);
        setDateState({
            ...dateState,
            month: previousMonth.month,
            year: previousMonth.year,
            current: dateState.current
        });
    };
    const gotoNextMonth = () => {
        const { month, year } = dateState;
        const nextMonth = getNextMonth(month, year);
        setDateState({
            ...dateState,
            month: nextMonth.month,
            year: nextMonth.year,
            current: dateState.current
        });
    };
    const gotoPreviousYear = () => {
        const { year } = dateState;
        setDateState({
            ...dateState,
            month: dateState.month,
            year: year - 1,
            current: dateState.current
        });
    };
    const gotoNextYear = () => {
        const { year } = dateState;
        setDateState({
            ...dateState,
            month: dateState.month,
            year: year + 1,
            current: dateState.current
        });
    };
    const handlePressure = (fn: any) => {
        if (typeof fn === 'function') {
            fn();
            pressureTimeout = setTimeout(() => {
                pressureTimer = setInterval(fn, 100);
            }, 500);
        }
    };
    const clearPressureTimer = () => {
        pressureTimer && clearInterval(pressureTimer);
        pressureTimeout && clearTimeout(pressureTimeout);
    };

    const renderCalendarDate = (date: string[], index: number) => {
        const { current, month, year } = dateState;
        const _date = new Date(date.join('-'));
        const isToday = isSameDay(_date, dateState.today);
        const isCurrent = current !== undefined && isSameDay(_date, current);
        const inMonth = isSameMonth(_date, new Date([year, month, 1].join('-')));
        const onClick = gotoDate(_date);
        return <>{renderDay(isCurrent, isToday, index, onClick, _date.toDateString(), inMonth)}</>;
    };

    const renderDay = (
        isCurrent: boolean,
        isToday: boolean,
        index: number,
        onClick: (evt: React.MouseEvent<HTMLElement>) => void,
        title: string,
        inMonth: boolean
    ) => {
        const css = classNames('calendar-day', {
            current: isCurrent,
            today: isToday,
            'in-month': inMonth
        });
        return (
            <div className={css} key={index} onClick={onClick}>
                {title}
            </div>
        );
    };

    return (
        <div className="calendar-container">
            {renderMonthAndYear()}
            <div className="calendar-grid">
                <>{Object.keys(WEEK_DAYS).map(renderDayLabel)}</>
                <>{getCalendarDates().map(renderCalendarDate)}</>
            </div>
        </div>
    );
};
