import React from 'react';
import './checkbox.style.scss';

interface Props {
    // props
    label: string;
    checked?: boolean;
    onCheckChange?: (checked: boolean) => void;
}

export const CheckBox: React.FC<Props> = ({ label, checked, onCheckChange }) => {
    const [isChecked, setIsChecked] = React.useState<boolean>(checked ? checked : false);

    const checkHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setIsChecked(value);
        if (onCheckChange) {
            onCheckChange(value);
        }
    };

    return (
        <label className="custom-checkbox">
            <input type={'checkbox'} checked={isChecked} onChange={checkHandler} />
            <span>{label}</span>
        </label>
    );
};
