import React from 'react';

export const useDebounce = <T>(value: T, delay: number): T => {
    const [state, setState] = React.useState<T>(value);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setState(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return state;
};
