import { Action, combineReducers } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { ISettingState, settingsReducer } from './settings/settings.reducer';
import { authReducer, IAuthState } from './auth/auth.reducer';
import instanceApi from '@api/api';

export interface IRootState {
    settings: ISettingState;
    auth: IAuthState;
}

export type DispatchThunk = ThunkDispatch<IRootState, void, Action>;

const reducer = combineReducers<IRootState>({
    settings: settingsReducer,
    auth: authReducer
});

const store = configureStore({
    reducer: reducer,
    middleware: [thunk.withExtraArgument(instanceApi.auth)],
    preloadedState: {}
});

export default store;
