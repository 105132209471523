import React from 'react';
import routes from './app/routes/routeConfig';
import { BrowserRouter } from 'react-router-dom';
import { Routing } from '@routes/routing';
import '@utils/i18n';
import { InterceptorWrapper } from '@routes/InterceptorWrapper';
import ApiProvider from '@api/api.provider';
import withToasts from '@shared/hoc/withToast';

export const AppComponent: React.FC = () => {
    return (
        <BrowserRouter>
            <InterceptorWrapper>
                <ApiProvider>
                    <Routing routes={routes} />
                </ApiProvider>
            </InterceptorWrapper>
        </BrowserRouter>
    );
};

const App = withToasts(AppComponent);

export default App;
