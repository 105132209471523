import { Icon } from '@ui-kit/icon.ui';
import { classNames } from '@utils/style.utils';
import React from 'react';
import './menu.style.scss';
import { ReactComponent as menu } from '@assets/icons/menu.svg';
import { ReactComponent as close } from '@assets/icons/close.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { routePaths } from '@routes/routePaths';
import { t } from 'i18next';
import useClickOutside from '@shared/hooks/use-click-outside';
import { useMediaQuery } from 'react-responsive';
import { size } from '@shared/constants';
import { AuthProps, withAuth } from '@shared/hoc/withAuth';

interface Props extends AuthProps {
    openClick: (isOpen: boolean) => void;
}

const MenuComponent: React.FC<Props> = ({ openClick, isAutorized, logout }) => {
    const [isMenuOpened, setIsMenuOpened] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const menuCss = classNames('menu', {
        opened: isMenuOpened
    });

    const { refClickOutside } = useClickOutside(() => setIsMenuOpened(false));

    React.useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    React.useEffect(() => {
        if (isMenuOpened) {
            scrollHandler();
            document.querySelector('#menu')?.classList.add('modal-open');
            document.body.style.overflow = 'hidden';
        } else {
            document.querySelector('#menu')?.classList.remove('modal-open');
            document.body.style.overflow = 'unset';
        }
        openClick(isMenuOpened);
    }, [isMenuOpened]);

    const mobile = useMediaQuery({ query: `(max-width: ${size.MAX_MOBILE_WIDTH}px)` });

    const scrollHandler = () => {
        const element = document.querySelector('.menu') as HTMLElement;
        const elementMenu = document.querySelector('.menu-items') as HTMLElement;
        if (window.pageYOffset > 0) {
            const value = `calc(${mobile ? '1' : '4'}rem + ${window.pageYOffset}px)`;
            const valueItems = `calc(${mobile ? '4' : '7'}rem + ${window.pageYOffset}px)`;
            element.style.top = value;
            if (elementMenu) {
                elementMenu.style.top = valueItems;
            }
        } else {
            element.style.removeProperty('top');
            if (elementMenu) {
                elementMenu.style.removeProperty('top');
            }
        }
    };

    return (
        <>
            <div id="menu" className={menuCss} onClick={() => setIsMenuOpened(!isMenuOpened)}>
                <Icon Svg={isMenuOpened ? close : menu} size={32} fill={'var(--color-bg)'} />
            </div>
            {isMenuOpened && (
                <ul className="menu-items" ref={refClickOutside}>
                    <li>
                        <NavLink
                            to={routePaths.home}
                            onClick={() => setIsMenuOpened(false)}
                            className={({ isActive }) => (isActive ? 'active' : undefined)}
                        >
                            {t('menu.home')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={routePaths.education}
                            onClick={() => setIsMenuOpened(false)}
                            className={({ isActive }) => (isActive ? 'active' : undefined)}
                        >
                            {t('menu.education')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={routePaths.job}
                            onClick={() => setIsMenuOpened(false)}
                            className={({ isActive }) => (isActive ? 'active' : undefined)}
                        >
                            {t('menu.job')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={routePaths.projects.root}
                            onClick={() => setIsMenuOpened(false)}
                            className={({ isActive }) => (isActive ? 'active' : undefined)}
                        >
                            {t('menu.projects')}
                        </NavLink>
                    </li>
                    {/* {!isAutorized && (
                        <li>
                            <NavLink
                                to={routePaths.login}
                                onClick={() => setIsMenuOpened(false)}
                                className={({ isActive }) => (isActive ? 'active' : undefined)}
                            >
                                {t('menu.login')}
                            </NavLink>
                        </li>
                    )} */}
                    {isAutorized && (
                        <li>
                            <a
                                onClick={() => {
                                    setIsMenuOpened(false);
                                    logout(() => navigate(routePaths.login));
                                }}
                            >
                                {t('menu.logout')}
                            </a>
                        </li>
                    )}
                </ul>
            )}
        </>
    );
};

export const Menu = withAuth(MenuComponent);
