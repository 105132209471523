import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CheckAuth } from './checkAuth';
import LoadingScreen from '@components/loading-screen/loading-screen.component';
import { RouteConfig } from './type';
import { Header } from '@components/layout/header/header.layout';
import { Footer } from '@components/layout/footer/footer.layout';
import { SettingsProps, withSettings } from '@shared/hoc/withSettings';
import { Menu } from '@components/layout/menu/menu.layout';
import { classNames } from '@utils/style.utils';
import { Page404 } from '@pages/404/404.page';

interface Props extends SettingsProps {
    routes: RouteConfig[];
}

const RoutingComponent: React.FC<Props> = ({ routes }) => {
    const [isMenuOpened, setIsMenuOpened] = React.useState<boolean>(false);

    const css = classNames('content', {
        blured: isMenuOpened
    });

    return (
        <Suspense fallback={<LoadingScreen />}>
            <Menu openClick={(isOpen) => setIsMenuOpened(isOpen)} />
            <div className={css}>
                <Header />
                <div className="wrapper-content">
                    <Routes>
                        {routes.map((route: RouteConfig, index: number) => GenerateRoute(route, index))}
                        <Route path="/" element={Page404} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
};

const GenerateRoute = (route: RouteConfig, index: number) => {
    return (
        <Route
            key={index}
            path={route.path}
            element={<CheckAuth WComponent={route.component} authRequire={route.authRequire} />}
        >
            {route.nestedRoutes &&
                route.nestedRoutes.length > 0 &&
                route.nestedRoutes.map((route: RouteConfig, index: number) => GenerateRoute(route, index))}
        </Route>
    );
};

export const Routing = withSettings(RoutingComponent);
