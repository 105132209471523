import { usePrevious } from '@shared/hooks/use-previous';
import { classNames } from '@utils/style.utils';
import React from 'react';
import './type-animation.style.scss';

interface Props {
    contents: string[];
    delay: number;
    repeatDelay: number;
    className?: string | undefined;
}

export const TypeAnimation: React.FC<Props> = ({ delay, contents, className, repeatDelay }) => {
    const [timer, setTimer] = React.useState<NodeJS.Timer | undefined>(undefined);
    const [contentTimer, setcontentTimer] = React.useState<NodeJS.Timer | undefined>(undefined);
    const [displayedContent, setDisplayedContent] = React.useState<string>('');
    const [indexContent, setIndexContent] = React.useState<number>(0);
    const [index, setIndex] = React.useState<number>(0);
    const prevIndex = usePrevious(index);
    const prevContent = usePrevious(contents);

    React.useEffect(() => {
        return () => {
            if (timer) {
                clearInterval(timer);
            }
            if (contentTimer) {
                clearTimeout(contentTimer);
            }
        };
    }, []);

    React.useEffect(() => {
        if (prevContent && prevContent !== contents) {
            if (contentTimer) {
                clearTimeout(contentTimer);
            }
            if (timer) {
                clearInterval(timer);
            }
            setDisplayedContent('');
            if (indexContent !== 0) {
                setIndexContent(0);
            } else {
                if (index !== 0 && prevIndex) {
                    setIndex(0);
                }
                setTimer(
                    setInterval(() => {
                        setIndex((i) => i + 1);
                    }, delay)
                );
            }
        }
    }, [contents]);

    React.useEffect(() => {
        if (index !== 0 && prevIndex) {
            setIndex(0);
        }
        setTimer(
            setInterval(() => {
                setIndex((i) => i + 1);
            }, delay)
        );
    }, [indexContent]);

    React.useEffect(() => {
        if (prevIndex && prevIndex !== index) {
            if (index > contents[indexContent].length - 1) {
                if (timer) {
                    clearInterval(timer);
                }
                setcontentTimer(
                    setTimeout(() => {
                        setDisplayedContent('');
                        setIndexContent((i) => {
                            if (i >= contents.length - 1) {
                                return 0;
                            }
                            return i + 1;
                        });
                        if (contentTimer) {
                            clearTimeout(contentTimer);
                        }
                    }, repeatDelay)
                );
            } else {
                setDisplayedContent((displayedContent) => displayedContent + contents[indexContent][index]);
            }
        } else {
            setDisplayedContent((displayedContent) => displayedContent + contents[indexContent][index]);
        }
    }, [index]);

    const css = classNames('type-writer', {
        className: className !== undefined
    });

    return <span className={css}>{displayedContent}</span>;
};
