import React from 'react';
import { classNames } from '@utils/style.utils';

interface Props {
    Svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    onClick?: () => void;
    width?: string | number;
    height?: string | number;
    className?: string;
    tooltip?: string;
    style?: React.CSSProperties;
    fill?: string;
    size?: number;
}

export const Icon: React.FC<Props> = (props: Props) => {
    const { Svg, className, tooltip, style, size, ...rest } = props;
    const cssClass = classNames('icon', className);

    return (
        <>
            {size ?
                <Svg className={cssClass} style={style} {...rest} width={size} height={size}>
                    {tooltip && <title>{tooltip}</title>}
                </Svg> :
                <Svg className={cssClass} style={style} {...rest}>
                    {tooltip && <title>{tooltip}</title>}
                </Svg>
            }
        </>
    );
};
